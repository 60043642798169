import './Title.css';

function Title(props) {
    return (
        <div>
            <p className={props.level} >
                {props.children}
            </p>
        </div>
    );
}

export default Title;