export default {
    "star": "",
    "star_half": "",
    "star_border": "",
    "email": "",
    "web": "",
    "phone_iphone": "",
    "add_location": "",
    "skill": "",
    "code": "",
    "extension": "",
    "cloud": "",
    "build": "",
    "cable": "",
    "layers": "",
    "brightness_1": "",
    "storage": "",
    "account": "",
    "work": "",
    "misc": ""
}