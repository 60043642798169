import './Resume.css';
import Title from '../../components/Title/Title.js'
import Text from '../../components/Text/Text.js'
import Spacer from '../../components/Spacer/Spacer.js';
import SkillLevel from '../../components/SkillLevel/SkillLevel.js';
import DualPanel from '../../components/DualPanel/DualPanel.js';
import Section from '../../components/Section/Section.js';
import { useEffect, useRef, useState } from 'react';
import '../../assets/Material-normal.js';
import { savePDF } from '../../util/PDFPrinter.js';

function Resume() {

  const [pageHeight, setPageHeight] = useState(0);

  const docRef = useRef();

  useEffect(() => {
    if (docRef.current.clientHeight > 0) {
      setTimeout(() => {
        // setPageHeight(docRef.current.clientHeight + 35);
        setPageHeight(docRef.current.clientHeight + 1);
      }, 500);
    }
  }, [])

  useEffect(() => {
    if (pageHeight > 0) {
      // create and save PDF
      savePDF("Resume", "doc", pageHeight);
    }
  }, [pageHeight])

  return (
    <div className="background">

      <div className='header'>
      </div>

      <div ref={docRef} id={'doc'} className="page paperBg">

        <div className='sidebar'>

          <Title level='h1'>Nadeeja Sirisena</Title>
          <Spacer />
          <Title level='h2'>Technical Lead</Title>
          <Spacer />

          <Text icon="email">nadeeja94@gmail.com</Text>
          <Text icon="web">www.nadeeja.com</Text>
          <Text icon="phone_iphone">+94 75 7218 914</Text>
          <Text icon="add_location">Colombo, Sri Lanka</Text>

          <Spacer /><Spacer /><Spacer /><Spacer /><Spacer />
          <Title level='h2'>Education</Title>

          <Spacer /><Spacer />
          <Title level='h3'>Bachelor of Engineering (Hons) in Software Engineering</Title>
          <Spacer />
          <Text >Staffordshire University – APIIT Sri Lanka (2012 – 2015)</Text>

          <Spacer /><Spacer /><Spacer /><Spacer /><Spacer />
          <Title level='h2'>Key Skills</Title>
          <Text icon="skill">Self-driven</Text>
          <Text icon="skill">Problem Solver</Text>
          <Text icon="skill">Team Player</Text>
          <Text icon="skill">Detail Oriented</Text>
          <Text icon="skill">Result Oriented</Text>
          <Text icon="skill">Team player</Text>

          <Spacer /><Spacer /><Spacer /><Spacer /><Spacer />
          <Title level='h2'>Technology Exposure</Title>

          <Spacer /><Spacer />
          <Title level='h3'>Languages</Title>
          <Spacer />

          <SkillLevel icon='code' level='4.5'>Java</SkillLevel>
          <SkillLevel icon='code' level='4.5'>JavaScript</SkillLevel>
          <SkillLevel icon='code' level='4'>C# .NET</SkillLevel>
          <SkillLevel icon='code' level='4'>SQL</SkillLevel>
          <SkillLevel icon='code' level='3.5'>Kotlin</SkillLevel>
          <SkillLevel icon='code' level='3'>Dart</SkillLevel>

          <Spacer /><Spacer /><Spacer />
          <Title level='h3'>Frameworks/Libraries</Title>
          <Spacer />
          <DualPanel>
            <Text icon='extension'>Springboot</Text>
            <Text icon='extension'>React</Text>
            <Text icon='extension'>Android</Text>
            <Text icon='extension'>Dagger</Text>
            <Text icon='extension'>Flutter</Text>
            <Text icon='extension'>NodeJS</Text>
            <Text icon='extension'>React-Native</Text>
            <Text icon='extension'>Mongo Realm</Text>
            <Text icon='extension'>Electron</Text>
            <Text icon='extension'>Angular</Text>
            <Text icon='extension'>ASP .NET</Text>
            <Text icon='extension'>SignalR</Text>
          </DualPanel>

          <Spacer /><Spacer /><Spacer />
          <Title level='h3'>Cloud Platforms/Tools</Title>
          <Spacer />
          <DualPanel>
            <Text icon='cloud'>AWS</Text>
            <Text icon='cloud'>Mongo Atlas</Text>
            <Text icon='cloud'>Terraform</Text>
            <Text icon='cloud' noJustify='true'>Azure (Basic experience)</Text>
          </DualPanel>

          <Spacer /><Spacer /><Spacer />
          <Title level='h3'>Database</Title>
          <Spacer />
          <DualPanel>
            <Text icon='storage'>MongoDB</Text>
            <Text icon='storage'>Redis</Text>
            <Text icon='storage'>MySQL</Text>
            <Text icon='storage'>Oracle</Text>
          </DualPanel>

          <Spacer /><Spacer /><Spacer />
          <Title level='h3'>Build Tools</Title>
          <Spacer />
          <DualPanel>
            <Text icon='build'>Maven</Text>
            <Text icon='build'>Gradle</Text>
            <Text icon='build'>npm/yarn</Text>
          </DualPanel>

          <Spacer /><Spacer /><Spacer />
          <Title level='h3'>Systems and Integrations</Title>
          <Spacer />
          <DualPanel>
            <Text icon='cable'>Kafka</Text>
            <Text icon='cable'>CircleCI</Text>
            <Text icon='cable'>Jenkins</Text>
            <Text icon='cable'>Docker</Text>
            <Text icon='cable'>Firebase</Text>
            <Text icon='cable'>Datadog</Text>
          </DualPanel>

          <Spacer /><Spacer /><Spacer />
          <Title level='h3'>Architectural Experience</Title>
          <Spacer />
          <DualPanel>
            <Text icon='layers'>Microservices</Text>
            <Text icon='layers'>MVC</Text>
          </DualPanel>

          <Spacer /><Spacer /><Spacer />
          <Title level='h3'>Miscellaneous Experience</Title>
          <Spacer />
          <DualPanel>
            <Text icon='misc'>3D Printing</Text>
            <Text icon='misc'>CAD</Text>
            <Text icon='misc'>Arduino/RPi</Text>
            <Text icon='misc'>VFX</Text>
            <Text icon='misc'>Unreal Engine</Text>
          </DualPanel>

          <Spacer /><Spacer /><Spacer /><Spacer /><Spacer />
          <Title level='h2'>References</Title>

          <Spacer /><Spacer />
          <Title level='h3'>Dilanka Muthukumarana</Title>
          <Spacer />
          <Text>Staff Software Engineer (Tech Lead)</Text><Spacer />
          <Text>IAM</Text><Spacer />
          <Text>Norway</Text><Spacer />
          <Text>+47 90 273 087</Text><Spacer />
          <Text>dilanka85@gmail.com</Text>

          <Spacer /><Spacer /><Spacer />
          <Title level='h3'>Kanchana Senadheera</Title>
          <Spacer />
          <Text>Software Engineer</Text><Spacer />
          <Text>Rakuten Group</Text><Spacer />
          <Text>Japan</Text><Spacer />
          <Text>kanchana@senadheera.net</Text>

        </div>

        <div className='mainSection'>

          <Section heading="Profile" icon="account">
            <Text size='13px'>Experienced (8+ years) full stack Software Engineer with a thirst for new and challenging problems. Specializing in
              backend with a proven track record in domains such as equipment rental, textile and food & beverage by contributing design,
              development and delpoyment expertise to deliver and support large-scale, robust and scalable enterprise software solutions.
              A seasoned engineer who welcomes a challenge with the capacity to design, lead and deliver high quality production ready solutions.
            </Text>
            <Spacer />

          </Section>

          <Section heading="Work Experience" icon="work">
          </Section>

          <Section subSection={true} heading="Zatca Integration - Grubtech (Pvt) Ltd">
            <Text weight="700">Role(s): Associate Technical Lead, Technical Lead (2022 - Present)</Text><Spacer />
            <Text point>Worked as the technical lead for a Tax Integration with Grubtech platform and Kingdom of Saudi Arabia government tax authority.</Text><Spacer />
            <Text point>Responsible for technical requirement analysis, solution architecture designing, leading the development team while problem solving.</Text><Spacer />
            <Text point>Participated in technical discussions with government represantatives from Zatca for technical clarifications and troubleshooting.</Text><Spacer />
            <Text point>Implemented strict cryptographic requirements mandated by Zatca concerning invoice signing and submission.</Text><Spacer />
            <Text point>Implemented extensive logging and monitoring with Slack integration to rectify any tax related issues.</Text><Spacer />
          </Section>

          <Section subSection={true} heading="Evaluate AWS Multi Region Expansion - Grubtech (Pvt) Ltd">
            <Text weight="700">Role(s): Technical Lead (2024)</Text><Spacer />
            <Text point>Performed an in depth feasibility study on expanding the Grubtech platform's scalability and availability by deploying on multiple geographical regions.</Text><Spacer />
            <Text point>Shortlisted a set of AWS regions based on service requirements and SLAs required for the Grubtech platform.</Text><Spacer />
            <Text point>Evaluated the regions for cost, latency requirements based on customer location statistics and regional data protection laws.</Text><Spacer />
            <Text point>Provided a POC and report recommending a multi region approach, suitable regions, infrastructure and internal platform alterations required to support multi region operation.</Text><Spacer />
          </Section>

          <Section subSection={true} heading="Feature Development and Maintaining Customer Facing Apps and Microservice Platform - Grubtech (Pvt) Ltd">
            <Text weight="700">Role(s): Associate Technical Lead, Technical Lead (2020 - Present)</Text><Spacer />
            <Text point>Responsible for end to end feature development for several business critical, customer facing Android and Web apps.</Text><Spacer />
            <Text point>Responsible for critical parts of the high volume order processing microservice platform with 150K+ orders processed per day.</Text><Spacer />
            <Text point>Focusing on clean API design, efficient, maintainable and testable code.</Text><Spacer />
            <Text point>Use CircleCI for CI/CD and Sonarqube for code quality metrics.</Text><Spacer />
            <Text point>Deploy and manage mission critical cloud infrastructure using Terraform.</Text><Spacer />
            <Text point>Plan and deploy critical services while ensuring minimal service disruptions.</Text><Spacer />
            <Text point>Assess and work on urgent production issues and hotfixes for backend and customer facing apps.</Text><Spacer />
            <Text point>Use Firebase and Datadog for continuous monitoring of production systems.</Text><Spacer />
            <Text point>Focus on performance, including backend service profiling, optimizing Kafka event payloads and API request/response sizes whenever possible.</Text><Spacer />
            <Text point>Conducting in-depth technical interviews.</Text><Spacer />
          </Section>

          <Section subSection={true} heading="Full Stack Engineer on client facing apps - Zone24x7 (Pvt) Ltd">
            <Text weight="700">Role(s): Software Engineer/Senior Software Engineer (2019 - 2020)</Text><Spacer />
            <Text point>Worked on backend feature development for .Net/.Net Core and NodeJS services.</Text><Spacer />
            <Text point>Worked on frontend feature development for customer facing React, Angular and Electron apps.</Text><Spacer />
            <Text point>Mentored junior developers and conducted technical interviews.</Text><Spacer />
          </Section>

          <Section subSection={true} heading="Android Based Shopping Kiosk for Kohls (USA) - Zone24x7 (Pvt) Ltd">
            <Text weight="700">Role(s): Software Engineer (2018 - 2019)</Text><Spacer />
            <Text point>Worked on POCs for evaluating the performance of React-Native, Cordova and Native Android for device requirements.</Text><Spacer />
            <Text point>Worked on an extensible library for React-Native to Native Android service inter process communication, which was adopted throughout the company for several projects.</Text><Spacer />
            <Text point>Extensive experience working with WebViews, script injection and performance optimization on low memory devices.</Text><Spacer />
          </Section>

          <Section subSection={true} heading="Full Stack Engineer on a Tooling Rental Platform for Siemens - Virtusa (Pvt) Ltd">
            <Text weight="700">Role(s): Software Engineer (2016 - 2018)</Text><Spacer />
            <Text point>Worked on feature development and maintenance of a APS.Net Web application.</Text><Spacer />
            <Text point>Worked on frontend feature development with Angular and JQuery.</Text><Spacer />
            <Text point>Experience in developing, maintaining and optimizing complex Stored Procedures in OracleDB with PLSQL.</Text><Spacer />
          </Section>

        </div>

      </div>

      <div className='footer'>
      </div>

    </div>
  );
}

export default Resume;