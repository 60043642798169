import './SkillLevel.css';
import MaterialFontMap from '../../util/MaterialFontMap';

function SkillLevel(props) {

    const getStar = (pos) => {
        const diff = pos - props.level;

        if (diff < 1) {
            if (pos % props.level == 0.5) {
                return <i className={'large material-icons'}>{MaterialFontMap['star_half']}</i>
            }
            return <i className={'large material-icons'}>{MaterialFontMap['star']}</i>;
        }

        return <i className={'large material-icons'}>{MaterialFontMap['star_border']}</i>
    }

    return (
        <div className='skillContainer'>
            <div className='skillTextContainer'>
                <i className={'medium material-icons'}>{MaterialFontMap[props.icon]}</i>
                <p className={`skillText`} >
                    {props.children}
                </p>
            </div>

            <div className='starContainer'>
                {getStar(1)}
                {getStar(2)}
                {getStar(3)}
                {getStar(4)}
                {getStar(5)}
            </div>

        </div>
    );

}

export default SkillLevel;