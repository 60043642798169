import { jsPDF } from "jspdf";

export const savePDF = (fileName, domId, height) => {

  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    // format: [793, Math.ceil(height)]
    format: [593, Math.ceil(height * 0.75)]
  });

  doc.html(
    document.getElementById(domId), {
    callback: (d) => {
      d.save(`${fileName}.pdf`);
    },
    window: 1024,
    x: 0,
    y: 0,
    html2canvas: {
      width: 1024,
      scale: 0.75,
      height: Math.ceil(height * 0.75)
    },
    margin: 0,
    autoPaging: 'slice',
    fontFaces: [
      {
        family: "MaterialIcons",
        src: [
          {
            format: 'truetype',
            url: 'MaterialIcons-Regular.ttf'
          }
        ]
      }
    ]
  });
}