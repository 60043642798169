import './Text.css';
import MaterialFontMap from '../../util/MaterialFontMap';

function Text(props) {
    return (
        <div className='textContainer'>
            {props.icon &&
                <i className={'large material-icons'}>{MaterialFontMap[props.icon]}</i>
            }

            {props.point &&
                <i style={{ fontSize: '9px', marginTop: '3px', marginRight: '10px'}} className={'large material-icons'}>{MaterialFontMap['brightness_1']}</i>
            }

            <p style={{fontSize: props.size || '12px', fontWeight: props.weight || 'normal'}} className={`text ${props.icon ? 'withIcon' : ''} ${props.noJustify ? '' : 'justified'}`} >
                {props.children}
            </p>
        </div>
    );
}

export default Text;