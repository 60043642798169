import './Section.css';
import MaterialFontMap from '../../util/MaterialFontMap';

function Section(props) {
    return (
        <div className='sectionContainer'>

            <div className='sectionInnerContainer'>

                {
                    !props.subSection &&
                    <div>
                        <div className='sectionHeadingContainer'>

                            <i className={'large material-icons'}>{MaterialFontMap[props.icon]}</i>
                            <p className='sectionHeading'>{props.heading}</p>
                        </div>

                        {
                            props.children &&
                            <div className='sectionContent'>
                                {props.children}
                            </div>
                        }
                    </div>
                }

                {
                    props.subSection &&
                    <div>
                        <div className='subSectionHeadingContainer'>

                            <i style={{ fontSize: '13px' }} className={'large material-icons'}>{MaterialFontMap['brightness_1']}</i>
                            <p className='subSectionHeading'>{props.heading}</p>
                        </div>

                        {
                            props.children &&
                            <div className='sectionContent'>
                                {props.children}
                            </div>
                        }
                    </div>
                }


            </div>

        </div>
    );
}

export default Section;