import './DualPanel.css';

function DualPanel(props) {

    return (
        <div className='dualPanelContainer'>
            {props.children}
        </div>
    );


}

export default DualPanel;